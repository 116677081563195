<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="6" md="6">
                <b-form-group
                    title="Chương trình đào tạo/Bồi dưỡng"
                  label-for="trainingSystemSelected"
                >
                  <v-select
                    v-model="trainingSystemSelected"
                    :options="trainingSystemsOptions"
                    :reduce="option => option.value"
                    @input="onChangeTrainingSystem"
                    placeholder="Chương trình đào tạo/Bồi dưỡng"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="6">
                <b-form-group
                    title="Khóa học"
                  label-for="courseSelected"
                >
                  <v-select
                    v-model="courseSelected"
                    :options="courseOptions"
                    :reduce="option => option.value"
                    @input="onChangeCourse"
                    placeholder="Khóa học"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" md="6" v-show="false">
                <b-form-group
                    title="Ngành học"
                    label-for="majorSelected"
                >
                  <v-select
                      v-model="majorSelected"
                      :options="majorOptions"
                      :reduce="option => option.value"
                      @input="onChangeMajor"
                      placeholder="Ngành học"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="6">
                <b-form-group
                  title="Học kỳ"
                  label-for="semesterSelected"
                >
                  <v-select
                    v-model="semesterSelected"
                    :options="semesterOptions"
                    :reduce="option => option.value"
                    placeholder="Học kỳ"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="6">
                <b-form-group
                    title="Lớp học"
                    label-for="classSelect"
                >
                  <v-select
                      v-model="classSelect"
                      :options="classOptions"
                      :reduce="option => option.value"
                      placeholder="Lớp học"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button v-show="this.classSelect > 0 && this.semesterSelected > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="onSelect"
                  >
                    <feather-icon icon="ListIcon" /> Xem danh sách
                  </b-button>
                  <b-button
                      v-show="this.itemsTable.length > 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-right: 5px"
                      @click="exportExcel"
                  >
                    <span class="text-nowrap text-right">Xuất excel</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div>
                  <b-table ref="table" :small="true" :hover="true" :items="itemsTable" :fields="fieldsTable">
                    <template #cell(index)="data">
                      {{ data.id}}
                    </template>
                    <template #cell(canhbao)="data">
                      {{ showSuspendStatus(data.canhbao) }}
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BOverlay, BRow, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'ClassLearningAlert',
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BButton,
    BFormGroup,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    vSelect,
    VueHtml2pdf,
  },
  data() {
    return {
      trainingSystemSelected: undefined,
      courseSelected: undefined,
      majorSelected: undefined,
      classSelect: undefined,
      semesterSelected: undefined,
      isLoading: false,
      contentRendered: false,
      generatingPdf: false,
      pdfDownloaded: false,
      controlValue: {
        showLayout: false,
        floatLayout: true,
        enableDownload: true,
        previewModal: true,
        paginateElementsByHeight: 1100,
        manualPagination: false,
        filename: 'Danhsachphach',
        pdfQuality: 2,
        pdfFormat: 'a4',
        pdfOrientation: 'portrait',
        pdfContentWidth: '800px',
      },
      contentPdf: {
        heDaoTao: 'Đại học chính quy',
        lop: null,
        namHoc: null,
        hocKy: null,
        phongThi: null,
        monHoc: null,
        lanThi: 1,
      },
      fieldsTable: [
        { key: 'index', label: '#' },
        { key: 'student_code', label: 'Mã học viên' },
        { key: 'student_fullname', label: 'Họ và tên' },
        { key: 'tbchk_hientai', label: 'Điểm TBCHK hiện tại' },
        { key: 'tbchk_tichluy', label: 'TBC tích lũy' },
        { key: 'so_tc_chuadat', label: 'Số TC chưa đạt' },
        { key: 'student_year', label: 'Học viên năm' },
        { key: 'so_tc_tichluy', label: 'Số TC tích lũy' },
        { key: 'diemrenluyen', label: 'Điểm rèn luyện' },
        { key: 'xephang', label: 'Xếp hạng' },
        { key: 'canhbao', label: 'Xử lý học tập' },
      ],
      itemsTable: [],
    }
  },
  computed: {
    ...mapGetters({
      dataLists: 'classLearningAlert/dataLists',
      allTrainingSystems: 'classLearningAlert/allTrainingSystems',
      listTrainingSystemCourses: 'classLearningAlert/listTrainingSystemCourses',
      listCourseMajors: 'classLearningAlert/listCourseMajors',
      listCourseMajorClasses: 'classLearningAlert/listCourseMajorClasses',
      allSemesters: 'classLearningAlert/allSemesters',
    }),
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: 'Danhsachphach.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      }
    },

    trainingSystemsOptions() {
      return this.allTrainingSystems.map(item => ({ value: item.id, label: item.name }))
    },
    courseOptions() {
      return this.listTrainingSystemCourses.map(item => ({ value: item.id, label: item.name }))
    },
    majorOptions() {
      return this.listCourseMajors.map(item => ({ value: item.id, label: item.name }))
    },
    classOptions() {
      return this.listCourseMajorClasses.map(item => ({ value: item.id, label: item.name }))
    },
    semesterOptions() {
      return this.allSemesters.map(item => ({ value: item.id, label: item.name }))
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.begin(),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      begin: 'classLearningAlert/begin',
      getData: 'classLearningAlert/getData',
      getListTrainingSystemCourses: 'classLearningAlert/getListTrainingSystemCourses',
      getListCourseMajors: 'classLearningAlert/getListCourseMajors',
      getListCourseMajorClasses: 'classLearningAlert/getListCourseMajorClasses',
    }),

    onChangeTrainingSystem() {
      if (this.trainingSystemSelected == null) {
        this.reset()
        this.$store.commit('classLearningAlert/SET_LISTTRAININGSYSTEMCOURSES', { data: [] })
        this.$store.commit('classLearningAlert/SET_LISTCOURSEMAJORS', { data: [] })
        this.$store.commit('classLearningAlert/SET_LISTCOURSEMAJORCLASSES', { data: [] })
        this.courseSelected = null
        this.majorSelected = null
        this.classSelect = null
      } else {
        this.getListTrainingSystemCourses({
          training_system_id: this.trainingSystemSelected,
        })
      }
    },
    onChangeCourse() {
      if (this.courseSelected == null) {
        this.reset()
        this.$store.commit('classLearningAlert/SET_LISTCOURSEMAJORS', { data: [] })
        this.$store.commit('classLearningAlert/SET_LISTCOURSEMAJORCLASSES', { data: [] })
        this.majorSelected = null
        this.classSelect = null
      } else {
        // this.getListCourseMajors({
        //   course_id: this.courseSelected,
        // })
        this.getListCourseMajorClasses({
          course_id: this.courseSelected,
        })
      }
    },
    onChangeMajor() {
      if (this.majorSelected == null) {
        this.reset()
        this.$store.commit('classLearningAlert/SET_LISTCOURSEMAJORCLASSES', { data: [] })
        this.classSelect = null
      } else {
        this.getListCourseMajorClasses({
          course_id: this.courseSelected,
          major_id: this.majorSelected,
        })
      }
    },
    async reset() {
      await this.$store.commit('classLearningAlert/SET_DATA', { data: [] })
      this.itemsTable = this.dataLists
      this.$refs.table.refresh()
    },
    async onSelect() {
      this.isLoading = true
      try {
        await this.getData({
          class_id: this.classSelect,
          course_id: this.courseSelected,
          semester_id: this.semesterSelected,
          student_id: 620,
        })
        this.itemsTable = this.dataLists
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    exportExcel() {
      this.isLoading = true
      if (this.itemsTable.length > 0) {
        try {
          const tHeader = ['Mã học viên', 'Họ và tên', 'Điểm TBCHK hiện tại', 'TBC tích lũy', 'Số TC chưa đạt', 'Học viên năm', 'Số TC tích lũy', 'Điểm rèn luyện', 'Xếp hạng', 'Cảnh báo']
          const filterVal = ['student_code', 'student_fullname', 'tbchk_hientai', 'tbchk_tichluy', 'so_tc_chuadat', 'student_year', 'so_tc_tichluy', 'diemrenluyen', 'xephang', 'canhbao']
          const dataJson = this.formatJson(filterVal, this.itemsTable)
          import('@/utils/Export2Excel').then(excel => {
            excel.export_json_to_excel({
              header: tHeader,
              data: dataJson,
              filename: 'Báo_cáo_cảnh_báo_học_tập_theo_lớp_cố_định',
              autoWidth: true,
              bookType: 'xlsx',
            })
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Chưa thực hiện lấy danh sách phách',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      }
    },

    async downloadPdf() {
      this.isLoading = true
      if (this.avgStudentClasss.length > 0) {
        try {
          if (!(await this.validateControlValue())) return
          this.$refs.html2Pdf.generatePdf()
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Chưa thực hiện lấy danh sách phách',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      }
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        alert('pdf-quality value should only be 0 - 2')
        this.controlValue.pdfQuality = 2

        return false
      }

      if (!this.controlValue.paginateElementsByHeight) {
        alert('paginate-elements-by-height value cannot be empty')
        this.controlValue.paginateElementsByHeight = 1400

        return false
      }

      const paperSizes = [
        'a0',
        'a1',
        'a2',
        'a3',
        'a4',
        'letter',
        'legal',
        'a5',
        'a6',
        'a7',
        'a8',
        'a9',
        'a10',
      ]

      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        alert(`pdf-format value should only be ${paperSizes}`)
        this.controlValue.pdfFormat = 'a4'

        return false
      }

      if (!this.controlValue.pdfOrientation) {
        alert('pdf-orientation value cannot be empty')
        this.controlValue.pdfOrientation = 'portrait'

        return false
      }

      if (!this.controlValue.pdfContentWidth) {
        alert('pdf-content-width value cannot be empty')
        this.controlValue.pdfContentWidth = '800px'

        return false
      }

      return true
    },

    showSuspendStatus(value) {
      if (value === -1) return 'Cảnh báo thôi học'
      if (value === 1) return 'Cho thôi học'
      return '---'
    },
  },
  beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
    this.reset()
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
